.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideUp {
  0% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes fadeInImage {
  0% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
}
@keyframes slideInFromLeft {
  0% { transform: translateX(-100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInFromBottom {
  0% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}
@keyframes fadeInFromTop {
  0% { transform: translateY(-50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}
.animate-fadeIn {
  animation: fadeIn 1.5s ease-out forwards;
}

.animate-slideUp {
  animation: slideUp 1.5s ease-out forwards;
}

.animate-fadeInImage {
  animation: fadeInImage 1s ease-out forwards;
}
.animate-slideInFromLeft {
  animation: slideInFromLeft 1.5s ease-out forwards;
}

.animate-fadeInFromBottom {
  animation: fadeInFromBottom 1.5s ease-out forwards;
}
.animate-fadeInFromTop {
  animation: fadeInFromTop 1.5s ease-out forwards;
}
